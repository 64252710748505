@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App {
  font-size: 1.2rem;

  h2 {
    color: black;
  }
}
.App-logo {
  // float: left;
  height: 4.5rem;
  pointer-events: none;
  // margin: 0 24px 16px 0;
  // @media (prefers-reduced-motion: no-preference) {
  //   animation: App-logo-spin infinite 20s linear;
  // }
}
.subtitle  {
  // position: absolute;
  // top: 4rem;
  // left: 0;
  // margin-left: 11.3rem;
  margin-top: 1.2rem;
  font-size: 1rem;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
}
.subtitle.dark {
  color: white;
}
.subtitle.light {
  color: #191919;
}
.App-sections {
  padding-top: 100px;
  min-height: 100vh;
}
.App-footer {
  color: white;
  background-color: #191919;
}
.App-footer-copyrights {
  text-align: center;
}

.ant-layout-header {
  position: fixed;
  // position: sticky;
  // top: 0;
  z-index: 1;
  width: 100%;
  height: 9rem;
  padding: 1.5rem 10rem;
  line-height: 0;
}
.ant-layout-header.dark {
  background: #03AD8C;
}
.ant-layout-header.light {
  background: #FFFFFF;
}
.ant-layout-content {
  margin-top: 0;
}
.ant-layout-header .ant-menu {
  line-height: none;
}
.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
  color: white;
  background: #03AD8C;
  margin-top: 0.8rem;
}
.ant-menu-horizontal {
  border-bottom: none;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
  background-color: transparent;
  border-bottom: none;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:not(:hover) {
  background-color: transparent;
  border-bottom: none;
}
.ant-menu-light.ant-menu-horizontal > .ant-menu-item:hover {
  background-color: transparent;
  border-bottom: none;
}
.ant-menu-light.ant-menu-horizontal > .ant-menu-item:not(:hover) {
  background-color: transparent;
  border-bottom: none;
}
.ant-menu-horizontal > .ant-menu-item a {
  color: #191919;
  font-size: 1.3rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}
.ant-menu-horizontal > .ant-menu-item a:hover {
  color: #DF4D95;
}
.ant-menu-horizontal > .ant-menu-item-selected a {
  color: #DF4D95;
}


.welcome-container {
  // margin-top: -1.2rem;
  // padding-top: 4.5rem;
  padding-top: 11rem;
  background-color: #03AD8C;
  min-height: 65rem;
}
.welcome-text {
  width: 80rem;
  margin: auto;
  font-size: 1.6rem;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  text-align: center;
  font-family: 'Raleway', sans-serif;
}
.welcome-heading {
  width: 80rem;
  margin: auto;
  margin-top: 5rem;
  font-size: 4rem;
  font-weight: 600;
  letter-spacing: 0px;
  color: #191919;
  text-shadow: 1px 2px 0px #19191988;
  opacity: 1;
  text-align: center;
  font-family: 'Poppins', sans-serif;
}
.btn-discover-container {
  width: 60%;
  margin: auto;
  margin-top: 7rem;
  text-align: center;
}
.btn-discover {
  font-family: 'Poppins', sans-serif;
  background-color: transparent;
  color: #404898;
  border: 0.4rem solid #404898;
  padding: 1rem 2rem;
  height: 5rem;
  font-size: 1.3rem;
  text-transform: uppercase;
  cursor: pointer;
}
.btn-discover:focus {
  outline: 0;
}
.solution-container {
  padding: 5rem 0;
  background-color: white;
  text-align: center;
}
.solution-container > h1 {
  font-size: 3.5rem;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  color: #404898;
  text-shadow: 1px 3px 0px #40489879;
}
.solution-cards-container {
  padding: 2rem 0;
}
.solution-card {
  padding: 4rem 7rem;
  font-family: 'Poppins', sans-serif;
}
.solution-card-content {
  text-align: "left"!important;
}
.solution-card-content .title {
  font-size: 2.2rem;
  font-weight: 600;
}
.solution-card-content .description {
  font-size: 1.5rem;
}
.btn-find-more {
  margin-top: 1.2rem;
  background-color: transparent;
  color: #03AD8C;
  border: 0.2rem solid #03AD8C;
  padding: 0.4rem 0.8rem;
  font-size: 1.5rem;
  cursor: pointer;
}


.page-wrapper {
  background-color: white;
}
.page-heading {
  padding-top: 11rem;
  background-color: white;
  // min-height: 65rem;
  text-align: center;
  font-family: 'Poppins', sans-serif;
}
.page-title {
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: 0px;
  color: #DF4D95;
  text-shadow: 2px 3px 0px #DF4D9577;
}
.page-subtitle {
  font-weight: normal;
}
.page-body {
  margin-top: 2.5rem;
  width: 100%;
}
.page-content {
  padding: 4rem 16rem;
}
.page-content.dark {
  color: white;
  background-color: #191919;
}
.page-content.dark > h3 {
  font-size: 1.9rem;
  font-weight: bolder;
  color: white;
}
.page-content.dark > p {
  font-size: 1.4rem;
}
.page-content.light {
  background-color: white;
}
.page-content.light > h3 {
  font-size: 1.9rem;
  font-weight: bolder;
}
.page-content.light > p {
  font-size: 1.4rem;
}


.ant-carousel {
  border-radius: 1rem;
  border: 0.2rem solid #707070 ;
  .slick-next {
    &::before {
      content: '';
    }
  }
  .slick-prev { 
    &::before {
      content: '';
    }
  }
}
.ant-carousel .slick-prev {
  left: -55px;
}
.ant-carousel .slick-next {
  right: -25px;
}
.image-carousel {
  width: 65%;
  border-radius: 1rem;
}