// COLORS
$color-primary-light: #7ed56f; // Light green:
$color-primary: #55c57a; // Medium green
$color-primary-dark: #28b485; // Dark green
$color-white: #fff;
$color-black: #000;
$color-grey: #777;
$color-font: #001529;
$color-dark-grey: rgb(100, 93, 93);
$color-complementary:#AE3C47;
$color-blue-light: #20639b; // Light blue:
$color-blue-medium: #3caea3; // Medium blue
$color-blue-dark: #173f5f; // Dark blue

// FONT
$default-font-size: 1.4rem;
